import { Price, PricesSummaryType, ProductsCash, ProductsLease } from '@content/types/prices';
import { ProductPrice } from '@content/types/product';
import { LeasingInfo, Product } from '@store/content/types';
import { BasketProduct, ConfiguratorBasket } from '@store/types';
import * as utils from '@utils';
import { PriceListPaymentTypes } from '@graphql/types';

const calcTariff = (
  basket: ConfiguratorBasket,
  fundingPriceListGroup: string | undefined
): Price => {
  const tariffPrice = utils.prices.getTariffPrice({
    tariff: basket.tariff,
    priceListGroup: fundingPriceListGroup,
  });
  const tariffPriceTotal =
    basket.tariff && tariffPrice
      ? basket.products.length
        ? basket.products.length * tariffPrice.iposFee +
          basket.tariffLocalizations * tariffPrice.locationFee
        : tariffPrice.iposFee + tariffPrice.locationFee
      : 0;
  return {
    net: tariffPriceTotal,
  };
};

const calcDiscount = (basket: ConfiguratorBasket): Price => {
  const discountCash =
    basket.discountCoupon && basket.products.length > 0 ? basket.discountCoupon.discount : 0;
  return {
    net: discountCash,
  };
};

const calcProductsFiscalization = (
  basket: ConfiguratorBasket,
  fundingPriceListGroup: string | undefined
): Price => {
  const fiscalizationPrice = utils.prices.getFiscalizationPrice({
    fiscalization: basket.fiscalization,
    priceListGroup: fundingPriceListGroup,
  });
  const fiscalizationPriceTotal =
    basket.fiscalization && fiscalizationPrice
      ? basket.products.length
        ? basket.products.length * fiscalizationPrice.iposFee
        : fiscalizationPrice.iposFee
      : 0;
  return {
    net: fiscalizationPriceTotal,
  };
};

const calcProductsAccessories = (basket: ConfiguratorBasket): Price => {
  const productsAccessories = basket.products.reduce((accessories, product) => {
    const productAccessories = product.accessories.reduce(
      (productAccessories, accessory) => productAccessories + accessory.price,
      0
    );
    const productColor = product.currentColor ? product.currentColor.price : 0;

    return accessories + productAccessories + productColor;
  }, 0);
  return {
    net: productsAccessories,
  };
};

const calcProductsCash = (
  basket: ConfiguratorBasket,
  fundingPriceListGroup: string | undefined
): ProductsCash => {
  const cash = basket.products.reduce(
    (cash, product) => {
      const productPrice = utils.prices.getProductPrice({
        product,
        paymentType: 'cash',
        priceListGroup: fundingPriceListGroup,
      });
      if (!productPrice) return cash;

      return {
        ...cash,
        ...(fundingPriceListGroup
          ? {
              reduced: {
                net: cash.reduced.net + productPrice.iposFee,
              },
            }
          : {
              net: cash.net + productPrice.iposFee,
            }),
      } as ProductsCash;
    },
    {
      net: 0, // total
      reduced: {
        net: 0, // total
      },
    } as ProductsCash
  );
  return cash;
};

const calcProductsLeasing = (amount: number, leasing: LeasingInfo) => {
  const selfDeposit = ((amount * leasing.selfdepositpercent) / 100);
  const installmentFromFund = (amount - selfDeposit) / leasing.installmentquantity;
  const monthCost = (installmentFromFund * leasing.yearpercent) / 100;
  const installmentToPay = monthCost + installmentFromFund;

  return {
    installment: +installmentToPay,
    deposit: +selfDeposit.toFixed(2),
  };
};

const calcProductsActivationPrice = (
  basket: ConfiguratorBasket,
  fundingPriceListGroup: string | undefined,
  paymentType: string
): Price => {
  const activationPrice = basket.products.reduce((activationPrice, product) => {
    const productPrice = utils.prices.getProductPrice({
      product,
      paymentType: paymentType,
      priceListGroup: fundingPriceListGroup,
    });
    if (!productPrice) return activationPrice;

    return activationPrice + (productPrice.activationPrice ? productPrice.activationPrice : 0);
  }, 0);
  return {
    net: activationPrice,
  };
};

const calcProductsLease = (
  basket: ConfiguratorBasket,
  fundingPriceListGroup: string | undefined
): ProductsLease => {
  const lease = basket.products.reduce(
    (lease, product) => {
      const productPrice = utils.prices.getProductPrice({
        product,
        paymentType: 'lease',
        priceListGroup: fundingPriceListGroup,
      });
      if (!productPrice) return lease;

      return {
        ...lease,
        ...(fundingPriceListGroup &&
        productPrice.reducedFeeDuration &&
        productPrice.reducedFeeDuration > 0
          ? {
              reduced: {
                net: lease.reduced.net + (productPrice.reducedFee ? productPrice.reducedFee : 0),
                duration: productPrice.reducedFeeDuration,
              },
              ...(productPrice.contractDuration &&
                productPrice.contractDuration > productPrice.reducedFeeDuration && {
                  afterReduced: {
                    net: lease.afterReduced.net + productPrice.iposFee,
                    duration: productPrice.contractDuration - productPrice.reducedFeeDuration,
                  },
                }),
            }
          : {
              net: lease.net + productPrice.iposFee,
              duration: productPrice.contractDuration,
            }),
      } as ProductsLease;
    },
    {
      net: 0, // total
      duration: 0,
      reduced: {
        net: 0, // total
        duration: 0,
      },
      afterReduced: {
        net: 0, // total
        duration: 0,
      },
    } as ProductsLease
  );

  return lease;
};

const getPricesLease = (
  basket: ConfiguratorBasket,
  grossMultiplier: number,
  paymentType = 'lease'
): PricesSummaryType => {
  const fundingPriceListGroup = utils.getFundingPriceListGroup(basket);
  const productsAccessories = calcProductsAccessories(basket);
  const productsLeaseActivation = calcProductsActivationPrice(
    basket,
    fundingPriceListGroup,
    paymentType
  );
  const productsLease = calcProductsLease(basket, fundingPriceListGroup);

  const productsLeaseReduced = productsLease.reduced;
  const productsLeaseAfterReduced = productsLease.afterReduced;
  const productsLeaseReducedDuration = productsLease.reduced.duration;
  const productsLeaseDuration = productsLease.duration;

  const totalNet = productsLeaseActivation.net + productsAccessories.net;
  const totalGross = totalNet * grossMultiplier;

  const totalMonthlyNet = productsLease.net + productsLeaseReduced.net;
  const totalMonthlyGross = totalMonthlyNet * grossMultiplier;

  return {
    oneOff: {
      productsLeaseActivation,
      productsAccessories,
      total: {
        net: totalNet,
        gross: totalGross,
      },
    },
    monthly: {
      productsLeaseDuration,
      productsLease,
      productsLeaseReduced,
      productsLeaseAfterReduced,
      productsLeaseReducedDuration,
      total: {
        net: totalMonthlyNet,
        gross: totalMonthlyGross,
      },
    },
  };
};

const getPricesLeaseTotal = (
  basket: ConfiguratorBasket,
  grossMultiplier: number
): PricesSummaryType => {
  let pricesSummary = getPricesLease(basket, grossMultiplier);
  pricesSummary = addTariff(pricesSummary, basket, grossMultiplier);
  pricesSummary = addFiscalization(pricesSummary, basket, grossMultiplier);

  return pricesSummary;
};

const getPricesCash = (basket: ConfiguratorBasket, grossMultiplier: number): PricesSummaryType => {
  const fundingPriceListGroup = utils.getFundingPriceListGroup(basket);
  const productsAccessories = calcProductsAccessories(basket);
  const productsCash = calcProductsCash(basket, fundingPriceListGroup);
  const productsCashReduced = productsCash.reduced;
  const productsCashAfterDiscountNet = productsCash.net + productsCashReduced.net;

  const totalNet =
    productsCashAfterDiscountNet + productsAccessories.net;
  const totalGross = totalNet * grossMultiplier;

  const totalMonthlyNet = 0;
  const totalMonthlyGross = totalMonthlyNet * grossMultiplier;

  return {
    oneOff: {
      productsAccessories,
      productsCash,
      productsCashReduced,
      total: {
        net: totalNet,
        gross: totalGross,
      },
    },
    monthly: {
      total: {
        net: totalMonthlyNet,
        gross: totalMonthlyGross,
      },
    },
  };
};

const getPricesCashTotal = (basket: ConfiguratorBasket, grossMultiplier: number): PricesSummaryType => {
  let pricesSummary = getPricesCash(basket, grossMultiplier);
  pricesSummary = addTariff(pricesSummary, basket, grossMultiplier);
  pricesSummary = addDiscountCash(pricesSummary, basket, grossMultiplier);
  pricesSummary = addFiscalization(pricesSummary, basket, grossMultiplier);

  return pricesSummary
};

const mergePrices = (
  priceLease: PricesSummaryType,
  priceCash: PricesSummaryType
): PricesSummaryType => {
  const merged = JSON.parse(JSON.stringify(priceLease));

  const recursiveMerge = (target, source) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (
          typeof source[key] === 'object' &&
          source[key] !== null &&
          !Array.isArray(source[key])
        ) {
          if (!target[key]) {
            target[key] = {};
          }
          recursiveMerge(target[key], source[key]);
        } else {
          if (typeof target[key] === 'number') {
            target[key] += source[key];
          } else {
            target[key] = source[key];
          }
        }
      }
    }
  }

  recursiveMerge(merged, priceCash);
  return merged;
}

const addTariff = (pricesSummary: PricesSummaryType, basket: ConfiguratorBasket, grossMultiplier: number) => {
  const fundingPriceListGroup = utils.getFundingPriceListGroup(basket);
  const tariff = calcTariff(basket, fundingPriceListGroup);

  pricesSummary.monthly.tariff = tariff;
  pricesSummary.monthly.total.net += tariff.net;
  pricesSummary.monthly.total.gross += tariff.net * grossMultiplier;

  return pricesSummary;
};

const addFiscalization = (pricesSummary: PricesSummaryType, basket: ConfiguratorBasket, grossMultiplier: number) => {
  const fundingPriceListGroup = utils.getFundingPriceListGroup(basket);
  const productsFiscalization = calcProductsFiscalization(basket, fundingPriceListGroup);

  pricesSummary.oneOff.productsFiscalization = productsFiscalization;
  pricesSummary.oneOff.total.net += productsFiscalization.net;
  pricesSummary.oneOff.total.gross += productsFiscalization.net * grossMultiplier;

  return pricesSummary;
};


const addDiscountCash = (pricesSummary: PricesSummaryType, basket: ConfiguratorBasket, grossMultiplier: number) => {
  const discountCash = calcDiscount(basket);

  pricesSummary.oneOff.discountCash = discountCash;
  pricesSummary.oneOff.total.net -= discountCash.net;
  pricesSummary.oneOff.total.gross -= discountCash.net * grossMultiplier;

  return pricesSummary;
};

const getPriceCashAndLeaseTotal = (basket: ConfiguratorBasket, grossMultiplier: number): PricesSummaryType => {
  const basketOfProductsWithLease = utils.getBasketOfProductsWithLeaseElements(basket);
  const basketOfProductsWithoutLease = utils.getBasketOfProductsWithoutLeaseElements(basket);
  const priceLease = getPricesLease(basketOfProductsWithLease, grossMultiplier, 'cash');
  const priceCash = getPricesCash(basketOfProductsWithoutLease, grossMultiplier);
  let mergedPricesSummary = mergePrices(priceLease, priceCash);

  // Calculate tariff, fiscalization and discount for both cash and lease.
  mergedPricesSummary = addTariff(mergedPricesSummary, basket, grossMultiplier);
  mergedPricesSummary = addDiscountCash(mergedPricesSummary, basket, grossMultiplier);
  mergedPricesSummary = addFiscalization(mergedPricesSummary, basket, grossMultiplier);

  return mergedPricesSummary;
};

const getPricesLeasing = (
  basket: ConfiguratorBasket,
  grossMultiplier: number,
  leasingInfo: LeasingInfo
): PricesSummaryType => {
  const cash = getPriceCashAndLeaseTotal(basket, grossMultiplier);
  let { net: totalNet, gross: totalGross = 0 } = cash.oneOff.total;
  const { productsFiscalization: productsFiscalization } = cash.oneOff;

  // Subtract fiscalization from leasing and add to oneOff.
  totalNet -= productsFiscalization ? productsFiscalization.net : 0;
  totalGross -= productsFiscalization ? productsFiscalization.net * grossMultiplier : 0;

  const leasingNet = calcProductsLeasing(totalNet, leasingInfo);
  const leasingGross = calcProductsLeasing(totalGross, leasingInfo);

  const totalLeasingNet = leasingNet.deposit + (productsFiscalization ? productsFiscalization.net : 0);
  const totalLeasingGross = leasingGross.deposit + (productsFiscalization ? productsFiscalization.net * grossMultiplier : 0);

  const totalLeasingMonthlyNet = leasingNet.installment + cash.monthly.total.net;
  const totalLeasingMonthlyGross = leasingGross.installment + cash.monthly.total.gross;

  return {
    oneOff: {
      leasingDeposit: {
        net: leasingNet.deposit,
      },
      productsFiscalization: cash.oneOff.productsFiscalization,
      total: {
        net: totalLeasingNet,
        gross: totalLeasingGross,
      },
    },
    monthly: {
      tariff: cash.monthly.tariff,
      productsLease: cash.monthly.productsLease,
      productsLeaseDuration: cash.monthly.productsLeaseDuration,
      productsLeaseReduced: cash.monthly.productsLeaseReduced,
      productsLeaseAfterReduced: cash.monthly.productsLeaseAfterReduced,
      productsLeaseReducedDuration: cash.monthly.productsLeaseReducedDuration,
      leasingInstallment: {
        net: leasingNet.installment,
        gross: leasingGross.installment,
      },
      leasingBlocked: totalNet > leasingInfo.maxprice,
      total: {
        net: totalLeasingMonthlyNet,
        gross: totalLeasingMonthlyGross,
      },
    },
  };
};

export const getConfiguratorPricesSummary = (
  basket: ConfiguratorBasket,
  paymentType: string | undefined,
  leasingInfo: LeasingInfo
): PricesSummaryType => {
  const grossMultiplier = 1.23;

  switch (paymentType) {
    case 'lease':
      return getPricesLeaseTotal(basket, grossMultiplier);
    case 'cash':
      return getPriceCashAndLeaseTotal(basket, grossMultiplier);
    case 'leasing':
      return getPricesLeasing(basket, grossMultiplier, leasingInfo);
    default:
      return {
        oneOff: {
          total: {
            net: 0,
            gross: 0,
          },
        },
        monthly: {
          total: {
            net: 0,
            gross: 0,
          },
        },
      };
  }
};

export const getAdditionalPrices = ({
  product,
  paymentType = 'cash',
  priceListGroup,
}: {
  product: Product | BasketProduct;
  paymentType?: string;
  priceListGroup?: string;
}): [ProductPrice] => {
  const additionalPrices: [ProductPrice] = [];

  if (product && product.deviceAdditionalPriceListNames && product.additionalPrices) {
    product.deviceAdditionalPriceListNames.forEach(priceListName => {
      if (priceListName in product.additionalPrices) {
        const additionalPrice = product.additionalPrices[priceListName].find(price => {
          return (
            (paymentType === 'lease' ? price.lease : !price.lease) &&
            findPrice(price, priceListGroup)
          );
        });
        if (additionalPrice) {
          additionalPrices.push(additionalPrice);
        }
      }
    });
  }
  return additionalPrices;
};

export const getProductPrice = ({
  product,
  paymentType = 'cash',
  priceListGroup,
}: {
  product: Product | BasketProduct;
  paymentType?: string;
  priceListGroup?: string;
}): ProductPrice | undefined => {
  if (!product || !product.prices) return undefined;

  const mainPrice: ProductPrice = {
    ...product.prices.find(price => {
      return (
        (paymentType === 'lease'
          ? price.paymentType == PriceListPaymentTypes.lease
          : price.paymentType == PriceListPaymentTypes.casch) && findPrice(price, priceListGroup)
      );
    }),
  };

  const additionalPrices = getAdditionalPrices({ product, paymentType, priceListGroup });

  if (additionalPrices.length > 0) {
    additionalPrices.forEach(additionalPrice => {
      if (mainPrice) {
        mainPrice.activationPrice =
          (mainPrice.activationPrice || 0) + (additionalPrice.activationPrice || 0) === 0
            ? undefined
            : (mainPrice.activationPrice || 0) + (additionalPrice.activationPrice || 0);
        mainPrice.iposFee = (mainPrice.iposFee || 0) + (additionalPrice.iposFee || 0);
        mainPrice.reducedFee =
          (mainPrice.reducedFee || 0) + (additionalPrice.reducedFee || 0) === 0
            ? undefined
            : (mainPrice.reducedFee || 0) + (additionalPrice.reducedFee || 0);
      }
    });
  }

  return mainPrice;
};

export const getFiscalizationPrice = ({ fiscalization, priceListGroup }) => {
  if (fiscalization && fiscalization.isFree) return { iposFee: 0 };
  if (!fiscalization || !fiscalization.prices) return undefined;
  return fiscalization.prices.find(price => findPrice(price, priceListGroup));
};

export const getTariffPrice = ({ tariff, priceListGroup }) => {
  if (!tariff || !tariff.prices) return undefined;
  return tariff.prices.find(price => findPrice(price, priceListGroup));
};

const findPrice = (price, priceListGroup) => {
  const priceListGroupPattern = priceListGroup && new RegExp(priceListGroup.toLowerCase());
  return priceListGroupPattern
    ? priceListGroupPattern.test(price.priceListGroup.toLowerCase())
    : price.isDefault;
};
